/* eslint-disable jsx-a11y/anchor-is-valid */

export const Footer = () => {
  return (
    <footer id="footer_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <aside className="widget widget_text">
              <a href="Home_01.html">
                <img
                  src="assets/img/logo/normal-logo.png"
                  width={250}
                  alt="footer logo"
                />
              </a>
              <p>
                In 2015, We launched Travellers with the belief that other
                travellers would share our desire to experience authentic
                adventures in a responsible and sustainable manner.
                <br />
                <br />
                We've grown from a one-man show to a company of over 50 world
                wide, and from a handful of trips in all over the world.
              </p>
              <a href="">
                Read More <i className="fa fa-angle-right"></i>
              </a>
            </aside>

            <aside className="widget widget_tag_cloud">
              <h4 className="widget-title">Tags</h4>
              <div className="tagcloud">
                <a href="">Tours & Travel</a>
                <a href="">Hotel Booking</a>
                <a href="">AirPort Transfers</a>
                <a href="">Car Hire</a>
                <a href="">Air Ticketing</a>
              </div>
            </aside>
          </div>
          <div className="col-md-4">
            <aside className="widget widget_recent_entries">
              <h4 className="widget-title">Recent Posts</h4>
              <ul>
                <li>
                  {" "}
                  <img src="/assets/img/footer/footer1.png" alt="Recent blog" />
                  <div>
                    <p>Popular tours in Uganda, Kenya and Tanzania...</p>
                    <a href="">Read More</a>
                  </div>
                </li>
                <li>
                  {" "}
                  <img src="/assets/img/footer/footer2.png" alt="Recent blog" />
                  <div>
                    <p>Best hotels to book in africa and all over the world</p>
                    <a href="">Read More</a>
                  </div>
                </li>
              </ul>
            </aside>
            <aside className="widget widget_links">
              <h4 className="widget-title">Other Services Offered</h4>
              <ul>
                <li>
                  <a href="">Umrah & Hajji Packages</a>
                </li>
                <li>
                  <a href="">Vacation Packages</a>
                </li>
                <li>
                  <a href="">Honeymoon Pacakges</a>
                </li>
                <li>
                  <a href="">Visa Assistance</a>
                </li>
                <li>
                  <a href="">Travel Insurance</a>
                </li>
                <li>
                  <a href="">Student Packages</a>
                </li>
                <li>
                  <a href="">UAE Visa</a>
                </li>
                <li>
                  <a href="">24 Hour Emergency Travel Service</a>
                </li>
              </ul>
            </aside>
          </div>
          <div className="col-md-4">
            <aside className="widget widget_search_availability">
              <div className="wsa_tab">
                <ul>
                  <li>
                    {" "}
                    <a href="#widget_global_search">
                      {" "}
                      <svg
                        version="1.1"
                        id="widget_global_search_icon"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="48.999px"
                        height="48.999px"
                        viewBox="0 0 48.999 48.999"
                        enableBackground="new 0 0 48.999 48.999"
                        xmlSpace="preserve"
                      >
                        <g>
                          <path
                            d="M20.255,35.438c0-0.003-0.001-0.005-0.002-0.008C20.295,35.453,20.319,35.464,20.255,35.438z M20.181,35.365
c-0.066-0.012,0.019,0.034,0.074,0.064C20.239,35.396,20.215,35.371,20.181,35.365z M25.726,22.385c0,0,0.011,0.012,0.025,0.026
C25.765,22.385,25.726,22.385,25.726,22.385z M33.384,34.127c0.176,0.024,0.41,0.006,0.577,0h0.053c0.169,0,0.315-0.07,0.581-0.217
l0.021-0.011l0,0c-0.22-0.027-0.437-0.056-0.655-0.082c-0.215-0.028-0.431-0.06-0.646-0.09c0.152,0.118,0.256,0.217,0.213,0.232
C33.41,34.009,33.192,34.106,33.384,34.127z M32.312,23.302l-0.038-0.019c-0.082,0.35-0.168,0.696-0.252,1.041
c0.059,0.036,0.017,0.048,0.261,0.059C32.633,24.4,32.39,24.4,32.646,24.4c0.259,0,0.305,0.028,0.351-0.078
c0.045-0.107,0.062,0.062,0.062-0.229s-0.135-0.411-0.229-0.487C32.737,23.532,32.586,23.424,32.312,23.302z M30.799,28.849
l-0.078,0.261l0.118-0.021c0,0,0.029,0.081,0.076,0.197c0.024-0.063,0.047-0.112,0.047-0.112s0-0.083-0.042-0.249
C30.912,28.898,30.858,28.871,30.799,28.849z M31.566,26.233H31.53l-0.034,0.138c-0.151,0.564-0.312,1.134-0.475,1.707
c0.13,0.006,0.251,0.016,0.251,0.016s0.146-0.062,0.188-0.249c0.041-0.188,0.146-0.396,0.146-0.396s0.036,0.008,0.105,0.021
c0.042-0.075,0.088-0.185,0.088-0.312c0-0.261-0.116-0.317-0.116-0.459c0-0.146,0.059-0.49,0.059-0.49L31.566,26.233z
M35.279,33.978L35.279,33.978c-0.115,0.299-0.242,0.612-0.321,0.733c-0.169,0.265-0.629,0.848-0.872,1.064
c-0.026,0.026-0.072,0.063-0.125,0.105c-0.367,0.299-1.211,0.933-1.402,1.082c-0.217,0.169-1.233,1.04-1.354,1.139
c-0.123,0.096-0.316,0.41-0.316,0.41l-0.169,0.244l0.074,0.193c0,0-0.146,0.168-0.194,0.34c-0.006,0.024-0.021,0.05-0.039,0.075
c-1.333,0.546-2.709,0.901-4.089,1.071c-0.268,0.558-0.54,1.105-0.82,1.645c2.616-0.168,5.237-0.925,7.662-2.324
c2.425-1.4,4.392-3.291,5.847-5.473c-0.588-0.025-1.181-0.063-1.774-0.108C36.684,34.122,35.981,34.057,35.279,33.978z
M48.941,31.046c-0.78,2.912-9.26,3.261-20.021,1.111c-3.52,10.394-8.06,17.562-10.971,16.782
c-1.834-0.489-2.651-4.046-2.465-9.301c0.062,0.034,0.125,0.069,0.187,0.104c-2.586-1.493-4.831-3.661-6.432-6.436
c-1.4-2.425-2.156-5.047-2.324-7.664c0.511,0.267,1.032,0.524,1.56,0.778c1.885,0.904,3.862,1.723,5.866,2.463h-0.004l0.005,0.108
l-0.291,0.509l-0.268,0.171l0.073,0.121l0.049,0.244H13.76c0,0,0,0.339,0,0.53c0,0.194,0.389,0.269,0.389,0.269l-0.122,0.074
c0,0,0.025,0.123,0.146,0.312c0.121,0.193,0.218,0.365,0.268,0.534c0.047,0.169,0.12,0.654,0.12,0.654s0.315,0.532,0.46,0.751
c0.146,0.218,0.436,0.51,0.558,0.752c0.121,0.242,0.388,0.122,0.896,0.242c0.508,0.12,0.727,0.29,0.727,0.29
s0.267,0.221,0.436,0.315c0.169,0.099,0.339,0.099,0.8,0.099c0.438,0,0.152,0.021,0.691,0.105l0.084,0.013
c0.646,0.097,0.134,0.052,0.75,0.328l0.024,0.012c0.149,0.067,0.23,0.104,0.272,0.121c0.047,0.111,0.026,0.322,0.043,0.41
c0.024,0.123,0.194-0.021,0.315-0.021c0.093,0,0.115,0.07,0.221,0.137c0.031,0.021,0.069,0.038,0.118,0.054
c0.219,0.073,0.266-0.073,0.266-0.073l0.243,0.292l0.194,0.268l-0.194,0.458l-0.169,0.17c0,0,0.024-0.218,0-0.387
c-0.025-0.171-0.169,0.072-0.169,0.072l-0.146,0.461l0.533,0.51l0.162,0.145l0.35,0.315l0.021,0.062l0.196,0.566l-0.024,0.194
l0.121,0.314c0,0-0.154,0.063-0.293,0.099c-0.053,0.014-0.103,0.022-0.142,0.022c-0.07,0-0.157,0.082-0.228,0.171
c-0.077,0.095-0.136,0.193-0.136,0.193s0.056,0.107,0.136,0.22c0.052,0.071,0.114,0.144,0.179,0.19
c0.033,0.023,0.064,0.043,0.095,0.063c0.026,0.004,0.052,0.01,0.077,0.015c-0.004,0.009-0.01,0.02-0.016,0.027l0,0l0,0
l-0.072,0.123l0,0c-0.208,0.362-0.475,0.811-0.78,1.283c-1.774-0.345-3.491-0.96-5.086-1.826c0.002,0.002,0.005,0.003,0.007,0.007
c0.048,3.176,0.642,5.232,1.803,5.544c2.206,0.591,5.775-5.276,8.726-13.855c-1.237-0.28-2.5-0.591-3.775-0.935
C9.332,27.116-0.864,21.391,0.058,17.95c0.491-1.835,4.043-2.651,9.3-2.465c-0.061,0.104-0.119,0.208-0.178,0.312
c1.496-2.641,3.69-4.931,6.51-6.557c2.806-1.621,5.873-2.376,8.895-2.356c-0.107,0-0.215-0.003-0.322-0.002
c2.466-4.645,4.952-7.314,6.786-6.822c3.442,0.922,3.301,12.615-0.315,26.114c-0.344,1.275-0.705,2.524-1.081,3.737
c8.905,1.734,15.772,1.579,16.363-0.626c0.311-1.16-1.175-2.705-3.903-4.335c0-0.008,0-0.016,0-0.024
c-0.047,1.819-0.37,3.62-0.962,5.333c-0.562,0.027-1.081,0.032-1.499,0.032l0,0c-0.341,0-0.608-0.005-0.766-0.011
c-0.454-0.019-0.915-0.042-1.374-0.073c0.081-0.284,0.151-0.494,0.169-0.61c0.021-0.146,0.104-0.376,0.104-0.376l-0.54-0.124
l-0.062-0.354l-0.23-0.038l-0.207,0.394c0,0-0.168,0.249-0.354,0.353c-0.188,0.104-0.437,0.125-0.437,0.125l0.062-0.292
c-0.104-0.04-0.312,0.04-0.312,0.04l-0.021-0.269c0,0-0.126-0.27-0.229-0.293c-0.104-0.021-0.271-0.25-0.271-0.25
s-0.062-0.081-0.126-0.249c-0.001-0.005-0.008-0.011-0.018-0.019l0.031-0.14c0,0,0.193-0.221,0.357-0.412
c0.166-0.193,0.222,0.191,0.387,0.331c0.165,0.136,0.11,0.358,0.11,0.358s0.11,0.055,0.247,0.055c0.139,0,0.194,0.109,0.194,0.109
l0.274,0.11l0.278-0.306c0,0,0.053-0.192,0.244-0.192c0.194,0,0.027,0.249,0.027,0.388c0,0.137,0.139,0,0.525-0.165
c0.384-0.165,0.22-0.138,0.577-0.332c0.358-0.192,0.166-0.137,0.304-0.247c0.14-0.109,0.248-0.221,0.248-0.221l0.302,0.191
l0.331-0.056l0.332-0.191v0.247c0,0-0.223,0.275-0.084,0.471c0.141,0.191,0.167,0.138,0.084,0.387
c-0.084,0.247-0.025,0.247,0,0.386c0.021,0.106,0.025,0.116,0.027,0.178c1.256-4.11,0.875-8.708-1.443-12.72
c-1.108-1.924-2.56-3.533-4.229-4.792c0.048-0.617,0.082-1.227,0.107-1.835c2.185,1.455,4.072,3.42,5.474,5.846
c1.606,2.784,2.362,5.824,2.356,8.824c0-0.084,0.003-0.168,0.002-0.251C46.764,26.728,49.434,29.213,48.941,31.046z M27.827,11.889
c0,0,0.173-0.1,0.259-0.136c0.085-0.037,0-0.05-0.086-0.111c-0.083-0.062-0.196,0.05-0.196,0.05s-0.049,0.049-0.099,0.162
c-0.005,0.01-0.012,0.018-0.017,0.023c-0.045,0.058-0.099-0.092-0.108-0.172c-0.014-0.084-0.335-0.025-0.323-0.025
c0.014,0,0.077,0.161,0.077,0.161s0.023,0.147,0.023,0.211c0,0.062,0,0.173,0.051,0.246c0.049,0.072,0.188,0.188,0.188,0.188
l0.096-0.063l0.038-0.025l0.148-0.247l0.123-0.111L27.827,11.889z M25.846,10.79c0,0,0.021-0.174,0.116-0.155
c0.098,0.021,0.193-0.234,0.193-0.234s-0.077-0.155-0.173-0.155c-0.099,0-0.313,0.02-0.313,0.02l-0.058-0.117
c0,0-0.116-0.019-0.271,0.039c-0.156,0.058-0.194-0.078-0.194-0.078l-0.02-0.234l-0.215,0.058l-0.155-0.135
c0,0-0.059-0.118,0.038-0.118c0.098,0,0.235,0.021,0.235,0.021l0.192-0.136l-0.076-0.154l0.135-0.156c0,0-0.271-0.136-0.331-0.038
c-0.058,0.096-0.271,0.174-0.271,0.174s0.019-0.058-0.098-0.214c-0.117-0.156,0.057-0.234-0.195-0.174
c-0.149,0.033-0.269,0.108-0.346,0.164c-0.053,0.039-0.083,0.069-0.083,0.069s-0.271,0.058-0.291,0.156
c-0.021,0.098,0.33,0.156,0.33,0.156s0.018-0.023,0.044-0.052c0.053-0.056,0.144-0.131,0.21-0.066
c0.097,0.097,0.174,0.215,0.174,0.215l-0.37,0.194l-0.014-0.013l-0.18-0.144h-0.37l-0.175,0.079L22.964,9.8
c0,0,0.058,0.175,0.058,0.272c0,0.097-0.156,0.193-0.156,0.193l-0.208-0.018l-0.026-0.001l-0.213,0.155
c0,0-0.234,0.021-0.331,0.098c-0.017,0.013-0.033,0.021-0.052,0.026c-0.088,0.023-0.182-0.038-0.182,0.091
c0,0.156,0.02,0.233-0.098,0.253c-0.024,0.004-0.055,0.01-0.089,0.017c-0.123,0.025-0.279,0.06-0.279,0.06
s-0.117,0.058-0.214,0.157c-0.097,0.096-0.312,0.154-0.312,0.154l-0.031-0.006l-0.358-0.072l-0.506,0.215l-0.013,0.03l-0.104,0.243
l-0.332,0.039c0,0-0.271,0.136-0.291,0.236c-0.01,0.049-0.061,0.093-0.109,0.126c-0.044,0.029-0.085,0.048-0.085,0.048
s-0.194,0.079-0.292,0.157c-0.098,0.078-0.215,0.234-0.215,0.234l-0.077,0.173l0.136,0.195l-0.312,0.039l0.039,0.154
c0.155,0.154,0.254,0.233,0.35,0.154c0.098-0.078,0.04-0.134,0.157-0.174c0.069-0.023,0.19-0.065,0.3-0.104
c0.077-0.027,0.146-0.054,0.187-0.07c0.099-0.039,0.254-0.174,0.37-0.174c0.116,0,0.234-0.022,0.234-0.022l0.045-0.036l0.265-0.216
l0.312,0.136l0.252-0.041c0,0,0,0.064,0.048,0.113c0.017,0.019,0.039,0.032,0.07,0.042c0.116,0.039,0.292,0.039,0.292,0.039
s0.097-0.135,0.234-0.135h0.241h0.108l0.039-0.175l0.222,0.103l0.032,0.014l0.174,0.137c0,0-0.079,0.018,0.117,0.097
c0.117,0.048,0.229,0.028,0.297,0.009c0.045-0.013,0.072-0.028,0.072-0.028l0.331,0.08c0,0,0.019-0.31-0.078-0.31
c-0.099,0-0.292-0.098-0.292-0.098l0.215-0.078l0.233-0.039l0.058,0.253l0.369-0.117l-0.058-0.213l0.02-0.333l0.351,0.06
l0.021,0.232c0,0,0.068,0.028,0.141,0.048c0.082,0.021,0.169,0.025,0.169-0.048c0-0.136-0.078-0.232,0.02-0.232
s0.292-0.06,0.292-0.06l0.097-0.135l0.137-0.312l0.156,0.233l0.215-0.134l-0.039-0.194l0.468-0.214c0,0,0.271-0.038,0.389-0.038
c0.117,0,0.156-0.039,0.156-0.039s0.077-0.157-0.039-0.157C25.943,10.888,25.846,10.79,25.846,10.79z M21.196,18.315l-0.194,0.097
l0.448,0.214l0.221,0.012l0.168,0.008c0,0,0.117-0.194,0.136-0.389c0.009-0.089,0.029-0.13,0.065-0.158
c0.041-0.033,0.104-0.052,0.188-0.115c0.155-0.118,0.116-0.155,0.078-0.271c-0.029-0.086-0.185-0.079-0.265-0.067
c-0.027,0.004-0.047,0.009-0.047,0.009l-0.323,0.162l-0.027,0.014l-0.156-0.194l-0.215,0.058l-0.213,0.213
c-0.02-0.019,0.136,0.022,0.136,0.022s0.058-0.022,0.215,0c0.156,0.02-0.039,0.154-0.039,0.154l-0.312,0.079L21.196,18.315z
M21.938,13.765l-0.265,0.021h-0.007c0,0-0.052,0.277,0.007,0.44c0.004,0.009,0.008,0.019,0.012,0.027
c0.056,0.111,0.17,0.221,0.356,0.248c0.077,0.01,0.165,0.007,0.266-0.015c0.174-0.039,0.229-0.049,0.238-0.049l-0.024,0.01
c0,0,0.035-0.011,0.024-0.01l0.118-0.047l0.15-0.06c0,0,0.252-0.156,0.252-0.293c0-0.134-0.215-0.096-0.39-0.096h-0.013
c-0.162-0.001-0.145-0.026-0.22-0.213c-0.078-0.195-0.078,0.115-0.078,0.115s-0.078-0.019-0.157-0.194
c-0.078-0.174-0.097-0.311-0.097-0.311l-0.069,0.036l-0.221,0.118l0.058,0.117L21.938,13.765z M21.744,19.348
c0,0,0.078,0.097,0.194,0.253c0.052,0.068,0.082,0.047,0.105,0c0.031-0.059,0.054-0.157,0.108-0.157
c0.098,0,0.02,0.157,0.136,0.134c0.116-0.019,0.37-0.019,0.37-0.019l0.006,0.006l0.168,0.188c0,0,0.194-0.097,0.215-0.193
c0.021-0.098,0.02-0.098,0.175-0.098c0.155,0,0.097-0.019,0.292-0.174c0.195-0.157-0.058-0.293-0.214-0.37
c-0.155-0.078-0.019-0.213-0.019-0.213l0.019-0.273c0,0,0-0.097-0.019-0.233c-0.02-0.136-0.058-0.235-0.078-0.37
c-0.021-0.136-0.157-0.096-0.157-0.096v-0.176c0,0,0.292,0,0.389-0.039c0.097-0.039,0-0.234,0-0.234s-0.232-0.097-0.409-0.155
c-0.174-0.058-0.058-0.097,0.234-0.116c0.291-0.021,0.039-0.155,0.039-0.155l-0.232-0.136c0,0-0.117,0.157-0.312,0.234
c-0.042,0.018-0.071,0.035-0.09,0.056c-0.069,0.065-0.023,0.143-0.084,0.236v0.156l0.084,0.145l0.052,0.087
c0,0-0.017,0.081-0.052,0.166c-0.017,0.043-0.039,0.087-0.065,0.128c-0.078,0.116,0.039,0.251,0.039,0.251s0,0.252-0.039,0.391
c-0.039,0.134-0.331-0.118-0.331-0.118l-0.058,0.331h-0.167h-0.105l0.04,0.175l0.065,0.052l0.108,0.084l0.116,0.176l-0.226-0.082
l-0.104-0.037L21.744,19.348z M18.688,10.576l-0.331,0.35c0,0,0.292,0.058,0.389,0.038c0.057-0.012,0.214-0.117,0.377-0.229
c0.116-0.081,0.234-0.162,0.322-0.219c0.215-0.134,0.292-0.097,0.292-0.097l0.078,0.155l-0.466,0.271l0.544-0.078l0.064-0.061
l0.207-0.192l0.215-0.252l0.33,0.02l0.125-0.048l0.283-0.107c0,0,0.233-0.155,0.33-0.33c0.076-0.136,0.127-0.06,0.221-0.039
c0.027,0.006,0.056,0.008,0.089,0c0.155-0.039,0.079-0.175,0.079-0.311c0-0.067,0.094-0.072,0.202-0.071
c0.114,0.001,0.245,0.013,0.304-0.027c0.116-0.078-0.097-0.233-0.253-0.271l-0.052-0.012c-0.122-0.027-0.185-0.042-0.202-0.163
c-0.01-0.071-0.083-0.094-0.168-0.089c-0.078,0.005-0.166,0.032-0.221,0.071c-0.117,0.078-0.35,0.097-0.271-0.058
c0.012-0.025,0.027-0.034,0.042-0.049c-0.605,0.127-1.208,0.289-1.803,0.488c0.134-0.003,0.191,0.008,0.282,0.008
c0.116,0-0.117,0.136-0.117,0.136s-0.351,0.116-0.447,0.192L19.12,9.613c-0.083,0.08-0.046,0.204-0.046,0.204s0.019,0,0.046,0
c0.063-0.001,0.177-0.005,0.244-0.02c0.097-0.021,0.175-0.058,0.428-0.215c0.08-0.049,0.129-0.076,0.162-0.089
c0.074-0.032,0.075-0.013,0.168-0.066c0.136-0.079,0.039,0.215,0.039,0.215l0.37-0.136l0.3-0.015l0.107-0.005l0.117-0.215
l-0.225-0.05l-0.125-0.027l0.117-0.117h0.008c0.054,0,0.389,0.001,0.498,0.02c0.117,0.02,0,0.157,0,0.157L21.29,9.411l-0.136,0.25
c0,0-0.176,0.021-0.312,0.021h-0.011c-0.135,0.009-0.281,0.154-0.281,0.154l-0.273,0.194h-0.323H19.89l-0.058,0.215l-0.312-0.039
c0,0-0.039-0.098-0.058-0.234c-0.02-0.136-0.157,0-0.157,0s-0.054,0.231-0.186,0.33c-0.026,0.021-0.055,0.032-0.085,0.04
C18.844,10.382,18.688,10.576,18.688,10.576z M22.923,8.518c-0.166,0.017-0.331,0.028-0.497,0.052
c0.022,0.005,0.038,0.014,0.092,0.025c0.117,0.02,0.02,0.058,0.02,0.154c0,0.065,0.026,0.085,0.123,0.105
c0.049,0.009,0.116,0.02,0.208,0.031c0.272,0.038,0.098-0.078,0.059-0.311C22.926,8.55,22.923,8.532,22.923,8.518z M21.833,8.662
c0.042,0.032,0.046,0.085,0.046,0.085s0.018-0.042,0.038-0.101C21.887,8.652,21.859,8.657,21.833,8.662z M24.11,8.444
c-0.021,0.043-0.039,0.095-0.033,0.149c0.02,0.176,0.059,0,0.175-0.039c0.071-0.025,0.085-0.052,0.104-0.116
C24.273,8.437,24.192,8.442,24.11,8.444z M12.707,13.594l0.245,0.016l0.311-0.192l0.311,0.097L13.4,13.593
c0,0-0.176,0.252-0.214,0.37c-0.039,0.116,0.466,0.097,0.466,0.097s0.136-0.369,0.136-0.524c0-0.155,0.409-0.253,0.409-0.253
l0.116-0.309l0.194,0.134c0,0,0.118-0.215,0.099-0.312c-0.02-0.097-0.37-0.118-0.37-0.118l0.351-0.173l0.351,0.097l-0.078,0.68
h0.35l0.079,0.117c0,0-0.486,0.273-0.585,0.273c-0.096,0-0.311,0.25-0.311,0.25l-0.194,0.215c0,0,0.077,0.117,0.232,0.155
c0.157,0.039,0.157,0.136,0.157,0.136s0.213-0.08,0.213-0.194c0-0.117-0.078-0.157-0.078-0.157s0.175-0.192,0.292-0.33
c0.116-0.136,0.74-0.408,0.856-0.544c0.116-0.136,0.039-0.234,0.039-0.234l0.292-0.176c0,0,0.078-0.369,0.116-0.465
c0.039-0.097,0.448-0.293,0.74-0.507c0.292-0.214,0.33-0.234,0.718-0.33c0.39-0.099,0.117-0.136,0.099-0.253
c-0.021-0.117-0.331,0.155-0.331,0.155l-0.467-0.133c0,0,0.194,0,0.389-0.155c0.194-0.156,0.157-0.039,0.292-0.097
c0.135-0.058,0.097-0.116,0.097-0.116h0.215c0,0,0.175-0.175,0.271-0.33c0.097-0.156,0.311-0.447,0.311-0.447
s-0.485,0.273-0.583,0.311c-0.097,0.038-0.253-0.038-0.369-0.038c-0.117,0-0.292,0.192-0.486,0.271
c-0.194,0.078-0.408,0.192-0.408,0.192l-0.292-0.058l-0.234,0.098l-0.563,0.234c0,0,0.097-0.078,0.331-0.214
c0.233-0.135,0.467-0.272,0.467-0.272l0.37-0.097l0.034-0.188c-0.149,0.08-0.299,0.156-0.446,0.242
C15.034,11.419,13.777,12.438,12.707,13.594z M27.344,29.422c0.39-1.245,0.768-2.533,1.124-3.861
c3.146-11.746,3.512-21.854,0.814-22.578c-1.162-0.312-2.704,1.173-4.334,3.901c-0.017,0-0.033,0-0.049,0
c1.829,0.042,3.637,0.367,5.357,0.961c0.027,0.564,0.032,1.082,0.032,1.497l0,0c0,0.343-0.005,0.613-0.013,0.77
c-0.054,1.477-0.188,2.989-0.382,4.508c-0.076-0.026-0.15-0.045-0.228-0.051c-0.208-0.014-0.213-0.003-0.209,0
c-0.003-0.002-0.178-0.143-0.243-0.254c-0.063-0.113-0.191-0.192-0.432-0.275c-0.244-0.081-0.226,0.096-0.226,0.096
s-0.228,0.162-0.516,0.131c-0.277-0.031-0.35,0.281-0.354,0.302v0.002l-0.207,0.177c0,0-0.114,0.193-0.243,0.193
c-0.131,0-0.227,0.032-0.227,0.032s-0.063,0.24-0.063,0.37c0,0.129-0.13,0.144-0.13,0.144s-0.161,0.032-0.271,0.081
c-0.112,0.048-0.226,0.112-0.337,0.176c-0.114,0.064-0.066,0.112-0.291,0.243c-0.226,0.129-0.306,0.177-0.306,0.177l-0.304,0.064
l0.16,0.162l-0.24,0.048c0,0-0.018,0.145,0.016,0.257c0.032,0.113,0.272,0.129,0.272,0.129s-0.16,0.13-0.351,0.21
c-0.194,0.082-0.194,0.112-0.179,0.192c0.018,0.08,0.13,0.255,0.452,0.338c0.32,0.08,0.095-0.017,0.241-0.097
c0.146-0.08,0.304-0.128,0.304-0.128s0.192,0.321,0.192,0.465c0,0.145-0.049,0.417-0.03,0.514c0.018,0.097,0.177-0.032,0.177-0.032
l-0.146,0.162c0,0,0.033,0.291,0.033,0.37c0,0.081,0.289,0.032,0.289-0.048s0.16-0.209,0.403-0.225
c0.241-0.017,0.207-0.048,0.207-0.242c0-0.194,0.052-0.452,0.052-0.452l0.271-0.08c0,0,0.159-0.119,0.255-0.216
c0.028-0.027,0.051-0.053,0.065-0.075c0.034-0.052-0.005-0.105-0.065-0.153c-0.045-0.042-0.107-0.082-0.144-0.121
c-0.081-0.08-0.111-0.337-0.111-0.498c0-0.162,0-0.097,0.016-0.209c0.015-0.103,0.135-0.111,0.241-0.135
c0.012-0.002,0.023-0.005,0.031-0.01c0.112-0.03,0.258-0.226,0.465-0.32c0.209-0.097-0.11-0.113-0.11-0.113
s0.029-0.178,0.064-0.322c0.029-0.144,0.45,0.047,0.45,0.047l0.161,0.193l-0.192,0.145l-0.226,0.338l-0.322,0.418l0.258,0.674
c0,0,0.226,0.112,0.37,0.096c0.146-0.016,0.307-0.097,0.402-0.176c0.041-0.035,0.245-0.089,0.472-0.137l-0.077,0.448
c-0.067,0.028-0.156-0.03-0.339-0.03c-0.25,0-0.318,0.114-0.548,0.136c-0.229,0.024-0.091,0.183-0.091,0.295
c0,0.113,0.319,0.251,0.319,0.251l-0.251,0.16c0,0-0.114-0.251-0.342-0.229c-0.229,0.024-0.138,0.319-0.272,0.432
c-0.139,0.115,0.089,0.458,0.089,0.458s-0.164,0.02-0.314,0.105c-0.017,0.011-0.031,0.021-0.05,0.031
c-0.158,0.114-0.204,0.024-0.204,0.024s-0.09-0.137-0.204-0.137c-0.116,0-0.272,0.184-0.39,0.228c-0.113,0.044-0.501,0-0.501,0
l-0.249-0.273l-0.207,0.205c0,0-0.182-0.045-0.296-0.182c-0.113-0.137,0-0.114-0.022-0.228c-0.022-0.115-0.068-0.115-0.068-0.115
l0.114-0.183l0.295-0.183c0,0,0.071-0.41-0.068-0.592c-0.136-0.184-0.136,0.181-0.136,0.181l-0.453,0.3l0.024,0.616l-0.092,0.272
l-0.183-0.045l-0.114,0.137c0,0-0.114-0.068-0.296-0.137c-0.183-0.069-0.501,0.228-0.501,0.228l-0.043,0.104l-0.073,0.176
l-0.25-0.045l-0.137,0.158c0,0-0.159,0-0.272,0c-0.113,0-0.047,0.205-0.047,0.205s-0.078,0.093-0.168,0.126l-0.041-0.054
c-0.133-0.018-0.165,0-0.249,0.066c-0.036,0.028-0.092,0.049-0.144,0.062c-0.065,0.016-0.122,0.022-0.122,0.022l-0.116-0.299
c0,0-0.083-0.148-0.165-0.032c-0.083,0.117-0.051,0.198-0.051,0.198l0.034,0.199l-0.166,0.018l-0.036-0.011l-0.08-0.024
l-0.29-0.173l-0.042-0.026c0,0-0.199-0.034-0.166,0.1c0.033,0.133,0.199,0.297,0.199,0.381c0,0.01,0.004,0.018,0.009,0.023
c0.051,0.055,0.29,0.039,0.29,0.143c0,0.116-0.15,0.249-0.15,0.249s0.116,0.099,0.116,0.248c0,0.148-0.033,0.364-0.067,0.464
c-0.032,0.099-0.115,0.066-0.149,0.165c-0.008,0.026-0.023,0.055-0.04,0.082c-0.052,0.084-0.125,0.167-0.125,0.167
s-0.248,0.067-0.331,0.033c-0.083-0.033-0.15-0.15-0.299-0.183c-0.036-0.009-0.061-0.009-0.08-0.007
c-0.06,0.009-0.069,0.039-0.219-0.062c-0.199-0.133-0.265-0.183-0.398-0.232c-0.133-0.049-0.199-0.165-0.199-0.165
s-0.024-0.016-0.06-0.035c-0.061-0.034-0.152-0.082-0.205-0.082c-0.083,0-0.216,0.15-0.216,0.15s0.033,0.134,0,0.298
c-0.032,0.165,0.033,0.248-0.066,0.315c-0.099,0.065-0.116,0.017-0.133,0.148c-0.017,0.133,0.066,0.066-0.065,0.198
c-0.119,0.12-0.063,0.118-0.149,0.178c-0.009,0.007-0.02,0.014-0.033,0.022c-0.133,0.083-0.184,0.065-0.267,0.199
c-0.083,0.132-0.115,0.282-0.115,0.398c0,0.114,0.365,0.53,0.365,0.53s0.021,0.003,0.05,0.013c0.06,0.02,0.149,0.059,0.149,0.137
c0,0.115-0.133,0.166,0,0.247c0.133,0.083,0.065,0.1,0.149,0.15c0.039,0.025,0.289,0.078,0.537,0.129
c0.271,0.055,0.541,0.104,0.541,0.104l0.183-0.134h0.153h0.163l0.083-0.282l0.248-0.017l0.017-0.266c0,0-0.034-0.265,0.05-0.265
c0.04,0,0.162-0.069,0.274-0.138c0.116-0.072,0.223-0.145,0.223-0.145s0.063,0.015,0.146,0.026c0.127,0.018,0.3,0.031,0.35-0.008
c0.083-0.066,0.266-0.331,0.266-0.331s-0.04-0.211,0.004-0.304c0.013-0.027,0.032-0.045,0.061-0.045
c0.133,0,0.449,0.018,0.548,0.115c0.1,0.1,0.348,0.234,0.448,0.216c0.086-0.014,0.222-0.103,0.323-0.169l0.042-0.027
c0.037-0.025,0.134-0.095,0.242-0.179c0.004,0.007,0.007,0.012,0.007,0.012s0.231,0.032,0.297,0.147
c0.066,0.117,0.1,0.332,0.066,0.465c-0.032,0.133,0.216,0.248,0.299,0.35c0.083,0.1,0.481,0.63,0.616,0.796
c0.132,0.165,0.397,0.464,0.397,0.464l0.135,0.315l-0.284,0.197c0,0-0.297-0.015-0.4-0.049c-0.098-0.033-0.182,0.065-0.182,0.065
s-0.066-0.031-0.215-0.165c-0.15-0.135-0.051,0.018-0.234,0.116c-0.182,0.101,0.234,0.246,0.332,0.332
c0.1,0.082,0.233,0.232,0.297,0.315c0.068,0.081,0.233-0.032,0.233-0.165c0-0.137,0.019-0.249,0.019-0.249h0.297l0.184-0.215
l0.148-0.135l0.067-0.184l-0.117-0.332c0,0,0.198-0.066,0.281-0.066c0.082,0,0.167,0.248,0.167,0.248l0.081,0.033
c0,0,0-0.199-0.051-0.282s-0.314-0.281-0.314-0.281s-0.299-0.116-0.383-0.185c-0.081-0.065-0.28-0.414-0.38-0.597
c-0.1-0.183-0.03-0.199-0.05-0.282c-0.017-0.083-0.183-0.185-0.246-0.299c-0.065-0.115-0.051-0.281-0.051-0.281
s0.149-0.101,0.316-0.199c0.058-0.034,0.082-0.059,0.09-0.072c0.066,0.069,0.24,0.266,0.24,0.388c0,0.148,0.067,0.315,0.102,0.414
c0.031,0.099,0.231,0.183,0.497,0.232c0.266,0.05,0.183,0.234,0.183,0.234s0.247,0.297,0.282,0.497
c0.032,0.197,0.032,0.298,0.048,0.548c0.02,0.249,0.135,0.282,0.217,0.513c0.082,0.232,0.117,0.232,0.168,0.413
c0.022,0.082,0.04,0.167,0.07,0.25c-0.167,0.609-0.341,1.224-0.521,1.84c-0.154,0.141-0.266,0.667-0.266,0.667
s-0.05,0.028-0.122,0.051l0,0l-0.346-0.082l0,0c-0.176-0.188-0.579-0.259-0.793-0.405c-0.218-0.144-0.218-0.459-0.387-0.604
c-0.169-0.146-0.291-0.073-0.291-0.073s-0.485-0.097-0.581-0.218c-0.053-0.067-0.139-0.163-0.208-0.244
c-0.06-0.068-0.107-0.12-0.107-0.12s0.047-0.047,0.107-0.097c0.072-0.063,0.166-0.133,0.231-0.146
c0.122-0.024-0.047-0.267-0.192-0.51c-0.145-0.242,0.388-0.315,0.363-0.437c-0.023-0.118-0.255-0.12-0.401-0.188l-0.011-0.005
c-0.145-0.073-0.485,0-0.485,0l-0.46-0.099c0,0-0.145,0.051-0.266,0.099c-0.044,0.019-0.1,0.009-0.158-0.019
c-0.105-0.046-0.216-0.148-0.28-0.273c-0.067-0.135-0.202-0.108-0.342-0.117c-0.065-0.004-0.129-0.017-0.19-0.054
c-0.066-0.042-0.123-0.055-0.179-0.054c-0.106,0.003-0.203,0.061-0.33,0.078c-0.161,0.02-0.37,0.072-0.506,0.09
c-0.028,0.004-0.054,0.007-0.076,0.007c-0.12,0-0.267,0.097-0.436,0.193s-0.218-0.267-0.218-0.267s-0.052,0-0.146-0.019
c-0.099-0.018-0.248-0.054-0.436-0.129c-0.363-0.146-0.194-0.363-0.194-0.363s-0.069,0.055-0.206,0.174
c-0.045,0.041-0.098,0.086-0.159,0.142c-0.242,0.218-0.313,0.315-0.532,0.292c-0.218-0.022-0.34,0.073-0.485,0.146
c-0.134,0.064-0.431,0.582-0.478,0.666c-2.887-0.992-5.742-2.159-8.322-3.526c-0.112-0.061-0.293-0.159-0.517-0.287l0,0
c-0.025-0.017-0.054-0.031-0.082-0.047c-0.024-0.013-0.045-0.027-0.07-0.04l0,0c-0.362-0.208-0.808-0.475-1.282-0.779
c0.347-1.795,0.972-3.529,1.854-5.142c-0.012,0.022-0.026,0.041-0.036,0.062c-3.176,0.048-5.23,0.642-5.542,1.804
c-0.723,2.696,8.215,7.437,19.96,10.585C24.766,28.818,26.067,29.138,27.344,29.422z M23.775,24.457
c-0.081,0.031-0.081,0.211-0.081,0.211s0.064,0.081,0.146,0.096c0.054,0.012,0.117,0.008,0.201-0.012
c0.04-0.009,0.085-0.021,0.14-0.036c0.162-0.048,0.065-0.131,0.097-0.243c0.032-0.112,0.032-0.26,0.098-0.374
c0.065-0.113-0.13-0.192-0.13-0.192s-0.15,0.209-0.205,0.296l-0.006,0.011C23.987,24.294,23.855,24.422,23.775,24.457z
M24.042,23.59c0.084-0.02,0.279-0.071,0.318-0.174c0.048-0.129,0.032-0.064,0.082-0.209c0.049-0.145-0.131,0.048-0.131,0.048
L24.1,23.386l-0.058,0.126l-0.039,0.084C24.004,23.596,24.017,23.595,24.042,23.59z"
                          />
                        </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                      </svg>
                    </a>{" "}
                  </li>
                  <li>
                    <a href="#widget_hotal_search">
                      {" "}
                      <svg
                        version="1.1"
                        id="widget_hotal_search_icon"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="510px"
                        height="510px"
                        viewBox="0 0 510 510"
                        enableBackground="new 0 0 510 510"
                        xmlSpace="preserve"
                      >
                        <g>
                          <g id="hotel">
                            <path
                              d="M382.5,51V0h-255v51H51v459h153v-51h102v51h153V51H382.5z M229.5,382.5h-51v-51h51V382.5z M229.5,280.5h-51v-51h51V280.5z
 M229.5,178.5h-51v-51h51V178.5z M331.5,382.5h-51v-51h51V382.5z M331.5,280.5h-51v-51h51V280.5z M331.5,178.5h-51v-51h51V178.5z"
                            />
                          </g>
                        </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="#widget_flight_search">
                      {" "}
                      <svg
                        version="1.1"
                        id="widget_flight_search_icon"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 375.04 375.04"
                        enableBackground="new 0 0 375.04 375.04"
                        xmlSpace="preserve"
                      >
                        <g>
                          <g id="Layer_5_77_">
                            <path
                              d="M365.938,8.173c-18.008-16.392-48.41-3.005-62.883,11.475c0,0-33.939,32.109-48.018,44.313
c-3.334,2.89-6.641,1.855-6.641,1.855L95.341,38.898c-6.58-0.938-15.801,2.136-20.494,6.836L61.185,59.392
c-4.703,4.695-3.691,10.982,2.244,13.967l112.941,58.683c0,0,8.709,4.326,3.459,9.326c-18.617,17.73-50.209,49.563-67.314,66.77
c-5.607,5.641-13.006,4.793-13.006,4.793l-52.154-6.371c-6.633-0.475-15.893,2.979-20.596,7.675L3.107,237.893
c-4.697,4.697-3.99,11.502,1.584,15.125l86.676,20.646c5.574,3.621,7.094,5.137,10.713,10.703l19.295,85.343
c3.623,5.569,10.43,6.277,15.127,1.583l23.658-23.663c4.693-4.694,8.156-13.957,7.689-20.579l-6.186-50.286
c0,0-0.918-6.397,2.596-9.958c17.371-17.601,51.955-51.27,70.17-69.719c5.65-5.721,8.043,1.451,8.043,1.451l58.281,112.134
c2.982,5.94,9.275,6.954,13.973,2.258l13.658-13.668c4.691-4.698,7.779-13.919,6.85-20.492L309.36,130.072
c0,0-1.113-5.204,2.023-8.592c11.238-12.143,44.842-48.672,44.842-48.672C370.692,58.334,384.83,25.368,365.938,8.173z"
                            />
                          </g>
                        </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                      </svg>{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#widget_local_search">
                      {" "}
                      <svg
                        version="1.1"
                        id="widget_local_search_icon"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="444.103px"
                        height="444.103px"
                        viewBox="0 0 444.103 444.103"
                        enableBackground="new 0 0 444.103 444.103"
                        xmlSpace="preserve"
                      >
                        <g>
                          <path
                            d="M0,219.744v48.098c0,8.301,6.729,15.03,15.03,15.03h40.208c7.529,19.854,26.738,34.011,49.199,34.011
c22.462,0,41.671-14.155,49.2-34.011h144.848c7.53,19.854,26.738,34.011,49.2,34.011s41.671-14.155,49.2-34.011h15.776
c18.779,0,27.955-8.109,30.259-15.723c5.828-19.267-11.758-53.979-12.028-54.314c-2.421-3.013-5.909-4.977-9.74-5.485
l-92.863-16.828c0,0-52.394-50.088-83.468-50.088l-18.642,0.007l-4.748-8.705c-1.519-2.784-4.437-4.516-7.607-4.516h-41.212
c-3.172,0-6.09,1.732-7.608,4.516l-4.761,8.729l-20.136,0.007c-20.302,0-37.269,15.47-56.915,33.382
c-9.257,8.44-19.478,17.729-30.751,25.99l-41.981,5.583C4.395,207.361,0,213.037,0,219.744z M327.645,264.278
c0-11.052,8.99-20.041,20.041-20.041s20.041,8.989,20.041,20.041c0,11.051-8.99,20.041-20.041,20.041
S327.645,275.327,327.645,264.278z M233.752,233.877h23.4v-23.399h23.398v23.399h-23.398v23.399h-23.4V233.877z M210.352,210.477
h23.399v23.399h-23.399V210.477z M163.553,210.477h23.399v23.399h23.4v23.398h-23.4v-23.398h-23.399V210.477L163.553,210.477z
M84.397,264.278c0-11.052,8.991-20.041,20.041-20.041c11.051,0,20.041,8.989,20.041,20.041c0,11.051-8.99,20.041-20.041,20.041
C93.388,284.317,84.397,275.327,84.397,264.278z"
                          />
                        </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                        <g> </g>
                      </svg>{" "}
                    </a>
                  </li>
                </ul>
              </div>
              <div id="widget_global_search" className="wsa_tab_content">
                <form>
                  <div className="travel_form_element">
                    <label>All Destination</label>
                    <select
                      className="selectpicker"
                      multiple
                      data-live-search="true"
                      title="Your Destination"
                    >
                      <option data-tokens="New Delhi, India (DEL)">
                        New Delhi, India (DEL)
                      </option>
                      <option data-tokens="Mumbai, India (BOM)">
                        Mumbai, India (BOM)
                      </option>
                      <option data-tokens="Bangalore, India (BLR)">
                        Bangalore, India (BLR)
                      </option>
                      <option data-tokens="Goa, India (GOI)">
                        Goa, India (GOI)
                      </option>
                      <option data-tokens="Kolkata, India (CCU)">
                        Kolkata, India (CCU)
                      </option>
                      <option data-tokens="Hyderabad, India (HYD)">
                        Hyderabad, India (HYD)
                      </option>
                      <option data-tokens="Dubai, UAE (DXB)">
                        Dubai, UAE (DXB)
                      </option>
                      <option data-tokens="Bangkok, Thailand (BKK)">
                        Bangkok, Thailand (BKK)
                      </option>
                      <option data-tokens="Singapore, Singapore (SIN)">
                        Singapore, Singapore (SIN)
                      </option>
                      <option data-tokens="New York, US - All Airports (NYC)">
                        New York, US - All Airports (NYC)
                      </option>
                    </select>
                  </div>
                  <div className="travel_form_element">
                    <label>Typology</label>
                    <select
                      className="selectpicker"
                      multiple
                      data-live-search="true"
                      title="Typology"
                    >
                      <option data-tokens="travel less">travel less</option>
                      <option data-tokens="stay shorter periods">
                        stay shorter periods
                      </option>
                      <option data-tokens="spend less">spend less</option>
                      <option data-tokens="prefer family road trips">
                        prefer family road trips
                      </option>
                      <option data-tokens="mobile homes & lower costs">
                        mobile homes & lower costs
                      </option>
                      <option data-tokens="friends & family">
                        friends & family
                      </option>
                      <option data-tokens="visit familiar tourist spots">
                        visit familiar tourist spots
                      </option>
                      <option data-tokens="travel more by air">
                        travel more by air
                      </option>
                    </select>
                  </div>
                  <div className="travel_form_element">
                    <label>Check in date</label>
                    <input
                      type="text"
                      id="Check_in_date_global_search"
                      placeholder="dd/mm/yyyy"
                    />
                    <i className="fa fa-calendar"></i>
                  </div>
                  <div className="travel_form_element">
                    <label>Check out date</label>
                    <input
                      type="text"
                      id="Check_out_date_global_search"
                      placeholder="dd/mm/yyyy"
                    />
                    <i className="fa fa-calendar"></i>
                  </div>
                  <div className="travel_form_element">
                    <label>Max-Budget</label>
                    <select
                      className="selectpicker"
                      data-live-search="true"
                      title="your Max-Budget"
                    >
                      <option data-tokens="Rs. 2000 and Below">
                        Rs. 2000 and Below"
                      </option>
                      <option data-tokens="Rs. 2001 - Rs. 5000">
                        Rs. 2001 - Rs. 5000
                      </option>
                      <option data-tokens="Rs. 2001 - Rs. 5000">
                        Rs. 2001 - Rs. 5000
                      </option>
                      <option data-tokens="Rs. 5001 - Rs. 10000">
                        Rs. 5001 - Rs. 10000
                      </option>
                      <option data-tokens="Rs. 10001 - Rs. 18000">
                        Rs. 10001 - Rs. 18000
                      </option>
                      <option data-tokens="Rs. 18001 - Rs. 25000">
                        Rs. 18001 - Rs. 25000
                      </option>
                      <option data-tokens="Rs. 25001 - Rs. 35000">
                        Rs. 25001 - Rs. 35000
                      </option>
                      <option data-tokens="Rs. 35001 and Above">
                        Rs. 35001 and Above
                      </option>
                    </select>
                  </div>
                  <div className="travel_form_element">
                    <button type="submit" className="btn-travel btn-yellow">
                      Search
                    </button>
                  </div>
                </form>
              </div>
              <div id="widget_hotal_search" className="wsa_tab_content">
                <form>
                  <div className="travel_form_element">
                    <label>All Destination</label>
                    <select
                      className="selectpicker"
                      multiple
                      data-live-search="true"
                      title="Your Destination"
                    >
                      <option data-tokens="New Delhi, India (DEL)">
                        New Delhi, India (DEL)
                      </option>
                      <option data-tokens="Mumbai, India (BOM)">
                        Mumbai, India (BOM)
                      </option>
                      <option data-tokens="Bangalore, India (BLR)">
                        Bangalore, India (BLR)
                      </option>
                      <option data-tokens="Goa, India (GOI)">
                        Goa, India (GOI)
                      </option>
                      <option data-tokens="Kolkata, India (CCU)">
                        Kolkata, India (CCU)
                      </option>
                      <option data-tokens="Hyderabad, India (HYD)">
                        Hyderabad, India (HYD)
                      </option>
                      <option data-tokens="Dubai, UAE (DXB)">
                        Dubai, UAE (DXB)
                      </option>
                      <option data-tokens="Bangkok, Thailand (BKK)">
                        Bangkok, Thailand (BKK)
                      </option>
                      <option data-tokens="Singapore, Singapore (SIN)">
                        Singapore, Singapore (SIN)
                      </option>
                      <option data-tokens="New York, US - All Airports (NYC)">
                        New York, US - All Airports (NYC)
                      </option>
                    </select>
                  </div>
                  <div className="travel_form_element">
                    <label>Amenities</label>
                    <select className="selectpicker" multiple title="Amenities">
                      <option data-tokens="Default">Default</option>
                      <option data-tokens="Room Services">Room Services</option>
                      <option data-tokens="Wi-Fi">Wi-Fi</option>
                      <option data-tokens="Pets Allowed">Pets Allowed</option>
                      <option data-tokens="Mini Bar">Mini Bar</option>
                      <option data-tokens="Cable TV">Cable TV</option>
                      <option data-tokens="Air Conditioning">
                        Air Conditioning
                      </option>
                      <option data-tokens="Free Parking">Free Parking</option>
                      <option data-tokens="Free Gym">Free Gym</option>
                      <option data-tokens="Free Pool">Free Pool</option>
                    </select>
                  </div>
                  <div className="travel_form_element">
                    <label>Check in date</label>
                    <input
                      type="text"
                      id="Check_in_date_hotal_search"
                      placeholder="dd/mm/yyyy"
                    />
                    <i className="fa fa-calendar"></i>
                  </div>
                  <div className="travel_form_element">
                    <label>Check out date</label>
                    <input
                      type="text"
                      id="Check_out_date_hotal_search"
                      placeholder="dd/mm/yyyy"
                    />
                    <i className="fa fa-calendar"></i>
                  </div>
                  <div className="travel_form_element">
                    <label>Accomodation</label>
                    <select className="selectpicker" title="Accomodation">
                      <option data-tokens="Default">Default</option>
                      <option data-tokens="Residence">Residence</option>
                      <option data-tokens="Hotal">Hotal</option>
                      <option data-tokens="Guest Hotal">Guest Hotal</option>
                      <option data-tokens="Resort">Resort</option>
                      <option data-tokens="Apartment">Apartment</option>
                      <option data-tokens="Bed & Breakfast">
                        Bed & Breakfast
                      </option>
                    </select>
                  </div>
                  <div className="travel_form_element">
                    <label>Rooms</label>
                    <input type="number" placeholder="No of rooms" />
                  </div>
                  <div className="travel_form_element">
                    <label>Max-Budget</label>
                    <select
                      className="selectpicker"
                      data-live-search="true"
                      title="your Max-Budget"
                    >
                      <option data-tokens="Rs. 2000 and Below">
                        Rs. 2000 and Below"
                      </option>
                      <option data-tokens="Rs. 2001 - Rs. 5000">
                        Rs. 2001 - Rs. 5000
                      </option>
                      <option data-tokens="Rs. 2001 - Rs. 5000">
                        Rs. 2001 - Rs. 5000
                      </option>
                      <option data-tokens="Rs. 5001 - Rs. 10000">
                        Rs. 5001 - Rs. 10000
                      </option>
                      <option data-tokens="Rs. 10001 - Rs. 18000">
                        Rs. 10001 - Rs. 18000
                      </option>
                      <option data-tokens="Rs. 18001 - Rs. 25000">
                        Rs. 18001 - Rs. 25000
                      </option>
                      <option data-tokens="Rs. 25001 - Rs. 35000">
                        Rs. 25001 - Rs. 35000
                      </option>
                      <option data-tokens="Rs. 35001 and Above">
                        Rs. 35001 and Above
                      </option>
                    </select>
                  </div>
                  <div className="travel_form_element">
                    <button type="submit" className="btn-travel btn-yellow">
                      Search
                    </button>
                  </div>
                </form>
              </div>
              <div id="widget_flight_search" className="wsa_tab_content">
                <form>
                  <div className="travel_form_element">
                    <label>Flight Type</label>
                    <select className="selectpicker" title="Flight Type">
                      <option data-tokens="Business">Business</option>
                      <option data-tokens="Economy">Economy</option>
                      <option data-tokens="First Class">First Class</option>
                      <option data-tokens="Premium">Premium</option>
                    </select>
                  </div>
                  <div className="travel_form_element">
                    <label>Flight Name</label>
                    <select className="selectpicker" title="Flight Name">
                      <option data-tokens="Major Airlines (350)">
                        Major Airlines (350)
                      </option>
                      <option data-tokens="United Airlines (260)">
                        United Airlines (260)
                      </option>
                      <option data-tokens="Delta Airlines (620)">
                        Delta Airlines (620)
                      </option>
                      <option data-tokens="US Airways (520)">
                        US Airways (520)
                      </option>
                      <option data-tokens="Air France (500)">
                        Air France (500)
                      </option>
                      <option data-tokens="Olympic (120)">Olympic (120)</option>
                      <option data-tokens="Emirates (280)">
                        Emirates (280)
                      </option>
                    </select>
                  </div>
                  <div className="travel_form_element">
                    <label>Flying From</label>
                    <select
                      className="selectpicker"
                      data-live-search="true"
                      title="From"
                    >
                      <option data-tokens="New Delhi, India (DEL)">
                        New Delhi, India (DEL)
                      </option>
                      <option data-tokens="Mumbai, India (BOM)">
                        Mumbai, India (BOM)
                      </option>
                      <option data-tokens="Bangalore, India (BLR)">
                        Bangalore, India (BLR)
                      </option>
                      <option data-tokens="Goa, India (GOI)">
                        Goa, India (GOI)
                      </option>
                      <option data-tokens="Kolkata, India (CCU)">
                        Kolkata, India (CCU)
                      </option>
                      <option data-tokens="Hyderabad, India (HYD)">
                        Hyderabad, India (HYD)
                      </option>
                      <option data-tokens="Dubai, UAE (DXB)">
                        Dubai, UAE (DXB)
                      </option>
                      <option data-tokens="Bangkok, Thailand (BKK)">
                        Bangkok, Thailand (BKK)
                      </option>
                      <option data-tokens="Singapore, Singapore (SIN)">
                        Singapore, Singapore (SIN)
                      </option>
                      <option data-tokens="New York, US - All Airports (NYC)">
                        New York, US - All Airports (NYC)
                      </option>
                    </select>
                  </div>
                  <div className="travel_form_element">
                    <label>Flying To</label>
                    <select
                      className="selectpicker"
                      data-live-search="true"
                      title="To"
                    >
                      <option data-tokens="New Delhi, India (DEL)">
                        New Delhi, India (DEL)
                      </option>
                      <option data-tokens="Mumbai, India (BOM)">
                        Mumbai, India (BOM)
                      </option>
                      <option data-tokens="Bangalore, India (BLR)">
                        Bangalore, India (BLR)
                      </option>
                      <option data-tokens="Goa, India (GOI)">
                        Goa, India (GOI)
                      </option>
                      <option data-tokens="Kolkata, India (CCU)">
                        Kolkata, India (CCU)
                      </option>
                      <option data-tokens="Hyderabad, India (HYD)">
                        Hyderabad, India (HYD)
                      </option>
                      <option data-tokens="Dubai, UAE (DXB)">
                        Dubai, UAE (DXB)
                      </option>
                      <option data-tokens="Bangkok, Thailand (BKK)">
                        Bangkok, Thailand (BKK)
                      </option>
                      <option data-tokens="Singapore, Singapore (SIN)">
                        Singapore, Singapore (SIN)
                      </option>
                      <option data-tokens="New York, US - All Airports (NYC)">
                        New York, US - All Airports (NYC)
                      </option>
                    </select>
                  </div>
                  <div className="travel_form_element">
                    <label>Flight Times</label>
                    <select className="selectpicker" title="Flight Times">
                      <option data-tokens="5am to 7am">5am to 7am</option>
                      <option data-tokens="7am to 9am">7am to 9am</option>
                      <option data-tokens="9am to 11am">9am to 11am</option>
                      <option data-tokens="11am to 1pm">11am to 1pm</option>
                      <option data-tokens="1pm to 3pm">1pm to 3pm</option>
                      <option data-tokens="3pm to 5pm">3pm to 5pm</option>
                      <option data-tokens="5pm to 7pm">5pm to 7pm</option>
                      <option data-tokens="7pm to 9pm">7pm to 9pm</option>
                      <option data-tokens="9pm to 11pm">9pm to 11pm</option>
                      <option data-tokens="11pm to 1am">11pm to 1am</option>
                      <option data-tokens="1am to 3am">1am to 3am</option>
                      <option data-tokens="3am to 5am">3am to 5am</option>
                    </select>
                  </div>
                  <div className="travel_form_element">
                    <button type="submit" className="btn-travel btn-yellow">
                      Search
                    </button>
                  </div>
                </form>
              </div>
              <div id="widget_local_search" className="wsa_tab_content">
                <form>
                  <div className="travel_form_element">
                    <label>All Destination</label>
                    <select
                      className="selectpicker"
                      multiple
                      data-live-search="true"
                      title="Your Destination"
                    >
                      <option data-tokens="New Delhi, India (DEL)">
                        New Delhi, India (DEL)
                      </option>
                      <option data-tokens="Mumbai, India (BOM)">
                        Mumbai, India (BOM)
                      </option>
                      <option data-tokens="Bangalore, India (BLR)">
                        Bangalore, India (BLR)
                      </option>
                      <option data-tokens="Goa, India (GOI)">
                        Goa, India (GOI)
                      </option>
                      <option data-tokens="Kolkata, India (CCU)">
                        Kolkata, India (CCU)
                      </option>
                      <option data-tokens="Hyderabad, India (HYD)">
                        Hyderabad, India (HYD)
                      </option>
                      <option data-tokens="Dubai, UAE (DXB)">
                        Dubai, UAE (DXB)
                      </option>
                      <option data-tokens="Bangkok, Thailand (BKK)">
                        Bangkok, Thailand (BKK)
                      </option>
                      <option data-tokens="Singapore, Singapore (SIN)">
                        Singapore, Singapore (SIN)
                      </option>
                      <option data-tokens="New York, US - All Airports (NYC)">
                        New York, US - All Airports (NYC)
                      </option>
                    </select>
                  </div>
                  <div className="travel_form_element">
                    <label>Typology</label>
                    <select
                      className="selectpicker"
                      multiple
                      data-live-search="true"
                      title="Typology"
                    >
                      <option data-tokens="travel less">travel less</option>
                      <option data-tokens="stay shorter periods">
                        stay shorter periods
                      </option>
                      <option data-tokens="spend less">spend less</option>
                      <option data-tokens="prefer family road trips (take more things)">
                        prefer family road trips (take more things)
                      </option>
                      <option data-tokens="mobile homes & lower costs">
                        mobile homes & lower costs
                      </option>
                      <option data-tokens="friends & family">
                        friends & family
                      </option>
                      <option data-tokens="visit familiar tourist spots">
                        visit familiar tourist spots
                      </option>
                      <option data-tokens="travel more by air">
                        travel more by air
                      </option>
                    </select>
                  </div>
                  <div className="travel_form_element">
                    <label>Check in date</label>
                    <input
                      type="text"
                      id="Check_in_date_local_search"
                      placeholder="dd/mm/yyyy"
                    />
                    <i className="fa fa-calendar"></i>
                  </div>
                  <div className="travel_form_element">
                    <label>Check out date</label>
                    <input
                      type="text"
                      id="Check_out_date_local_search"
                      placeholder="dd/mm/yyyy"
                    />
                    <i className="fa fa-calendar"></i>
                  </div>
                  <div className="travel_form_element">
                    <label>Max-Budget</label>
                    <select
                      className="selectpicker"
                      data-live-search="true"
                      title="your Max-Budget"
                    >
                      <option data-tokens="Rs. 2000 and Below">
                        Rs. 2000 and Below"
                      </option>
                      <option data-tokens="Rs. 2001 - Rs. 5000">
                        Rs. 2001 - Rs. 5000
                      </option>
                      <option data-tokens="Rs. 2001 - Rs. 5000">
                        Rs. 2001 - Rs. 5000
                      </option>
                      <option data-tokens="Rs. 5001 - Rs. 10000">
                        Rs. 5001 - Rs. 10000
                      </option>
                      <option data-tokens="Rs. 10001 - Rs. 18000">
                        Rs. 10001 - Rs. 18000
                      </option>
                      <option data-tokens="Rs. 18001 - Rs. 25000">
                        Rs. 18001 - Rs. 25000
                      </option>
                      <option data-tokens="Rs. 25001 - Rs. 35000">
                        Rs. 25001 - Rs. 35000
                      </option>
                      <option data-tokens="Rs. 35001 and Above">
                        Rs. 35001 and Above
                      </option>
                    </select>
                  </div>
                  <div className="travel_form_element">
                    <button type="submit" className="btn-travel btn-yellow">
                      Search
                    </button>
                  </div>
                </form>
              </div>
            </aside>
            <aside className="widget payment_method">
              <h4 className="widget-title">Supported Payment Method</h4>
              <a href="">
                <img
                  src="/assets/images/Payment-Images.png"
                  alt="Payment Method"
                />
              </a>
            </aside>
          </div>
        </div>
      </div>
    </footer>
  );
};
